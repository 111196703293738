<template>
  <eagle-list
    :list-key="listKey"
  ></eagle-list>
</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './pageListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'page-list',
    meta: {},
  }),
  methods: {
    async loadCategories() {
      let result = null
      try {
        result = await this.$api.collection.pageCategoryApi.flatTree(this.pageType)
      } catch (error) {
        console.warn(error)
      }

      this.meta.depthOfType = result.depth_of_type
      this.meta.categories = result.nodes
    },
    async beforeIndex() {
      await Promise.all([
        this.loadCategories(),
      ])
    },
    async indexApi(params) {
      if(!params.filter) params.filter = {}
      params.filter.type = this.pageType
      return await this.$api.collection.pageApi.index(params)
    },
    async patchApi(value, row, config) {
      const target = row.id
      const column = config.key
      return await this.$api.collection.pageApi.patch(target, column, value)
    },
    async deleteApi(target) {
      return await this.$api.collection.pageApi.delete(target)
    },
    async batchApi(targets, column, value) {
      return await this.$api.collection.pageApi.batch(targets, column, value)
    },
    async batchDeleteApi(targets) {
      return await this.$api.collection.pageApi.batchDelete(targets)
    },
    getListConfig() {
      return listConfig
    },
  },
  computed: {
    pageType() {
      return this.$route.meta.type
    },
    frontendDomain() {
      return this.$store.getters['base/eagleEnv'].frontendDomain
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
